.projectList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    border-radius: 5px;
    margin-left: 10%;
    margin-top: 11vw;
    width: 60vw;
}

.projectItem {
    margin-top: 20px;
    margin: 20px;
    position: relative;
    overflow: hidden;
    flex: 1;
    /* This ensures equal distribution of space among items */
    max-width: calc(33.333% - 20px);
    /* Adjust the percentage and margin as needed */
    box-sizing: border-box;
    border-radius: 15px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    overflow-y: auto;


    .bgImage {
        background-size: cover;
        width: 100%;
        /* Cover the entire width of the container */
        height: 50vh;
        /* Adjust the height as needed */
        opacity: 0;
        animation: fadeIn 1s 1.5s;
        animation-fill-mode: forwards;

    }

    h1 {
        color: antiquewhite;
        font-size: 1.3vw;
        margin: 10px 0;
        text-align: center;
        animation: pulse 1s;

        &:nth-of-type(1) {
            animation-delay: 2.1s;
        }
    }

    &:hover {
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
        transition: 0.3s ease-in;
        cursor: pointer;
    }
}