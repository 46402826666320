h1 {
    font-size: 2.5vw;
    font-family: 'Coolvetica';
    color: #ffd700;
    font-weight: 400;
    margin-top: 1.5vh;
    /* Use viewport height for responsive margin */
    margin-bottom: 4vh;
    /* Use viewport height for responsive margin */
    margin-left: 2vw;
    /* Use viewport width for responsive position */
}

h3 {
    padding-top: 0.25em;
}

.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
        0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 1em 2em !important;
}

.date {
    color: rgb(201, 251, 255);
}

#description {
    margin: 1em 1em 2em 0;
    font-size: 0.7vw;
    /* Use viewport width for responsive font size */
}

.button {
    text-decoration: none;
    padding: 1vh 1vw;
    /* Use viewport height and width for responsive padding */
    border-radius: 5px;
    color: white;
}

.workButton {
    background-color: #06d6a0;
}

.workButton:hover {
    background-color: #0ac593;
}

.schoolButton {
    background-color: #f9c74f;
}

.schoolButton:hover {
    background-color: #f3bc3c;
}

/* Media Query for smaller screens (adjust as needed) */
@media screen and (max-width: 700px) {
    h1 {
        font-size: 3vw;
        margin-top: 2vh;
        margin-bottom: 2vh;
        left: 0;
    }

    #description {
        font-size: 1.3vw;
    }

    .button {
        padding: 1.5vh 3vw;
    }
}