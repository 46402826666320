.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags {
    bottom: auto;
    top: 35px;
}

.tags {
    color: #ffd700;
    opacity: 0.6;
    position: fixed;
    bottom: 0;
    left: 120px;
    font-size: 1vw;
    font-family: 'La Belle Aurore';
}

.bottom-tag-html {
    margin-left: -1.5vw;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 200px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.about-page,
.contact-page,
.project-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        h1 {
            font-size: 3.5vw;
            font-family: 'Coolvetica';
            color: #ffd700;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 5.5vh;
            margin-left: 1vw;

            &::before {
                content: '<h1>';
                font-family: 'La Belle Aurore';
                font-size: 1.2vw;
                position: absolute;
                margin-top: -1vh;
                left: -0.5vw;
                opacity: 0.6;
                line-height: 1.8vh;
            }

            &::after {
                content: '<h1/>';
                font-family: 'La Belle Aurore';
                font-size: 1.2vw;
                line-height: 1.8vh;
                position: absolute;
                left: -0.5vw;
                bottom: -2.5vh;
                margin-left: -0.5vw;
                opacity: 0.6;
            }
        }

        p {
            font-size: 1vw;
            color: antiquewhite;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }
        }
    }

    .text-animate-hover {
        &:hover {
            color: antiquewhite;
        }
    }
}

.project-page {
    .text-zone {
        top: 14%;
        left: 7%;
    }
}

.about-page {
    position: fixed;
}