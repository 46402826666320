.project {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    animation: fadeIn 1s 0.5s;
    animation-fill-mode: forwards;
}

.project h1 {
    margin-top: 0px;
    margin-bottom: -10px;
    color: #ffd700;
    font-size: 2.5vw;
    animation: pulse 1s;
    font-family: 'Coolvetica';
    font-weight: 200;

    &:nth-of-type(1) {
        animation-delay: 1.1s;
    }
}

.project img {
    margin-top: 30%;
    width: 25vw;
    height: 50vh;
    border-radius: 10px;
}

.project p {
    margin-bottom: 50px;
    font-size: 1.2vw;
    color: antiquewhite;
    font-family: 'La Belle Aurore';
    animation: pulse 1s;

    &:nth-of-type(1) {
        animation-delay: 2.1s;
    }
}

.gitIcon {
    font-size: 2.3vw;
    color: rgba(#000000, 0.7);
    position: relative;

    &:hover {
        color: #ffd700 !important;
    }
}


.imageSlider {
    position: relative;
    height: 100%;
}

.imageSliderSlide {
    margin-top: -50px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
}

.imageSliderControls {
    position: absolute;
    top: 55%;
    transform: translate(0, -50%);
    font-size: 45px;
    color: #fff;
    cursor: pointer;
    z-index: 1;

    &:hover {
        transition: 0.3s ease-in;
        cursor: pointer;
        color: #ffd700 !important;
    }
}

.imageSliderControlsLeft {
    left: -40px;
}

.imageSliderControlsRight {
    right: -32px;
}